import { withAuthenticationRequired } from '@auth0/auth0-react'
import { PageProps } from 'gatsby'
import Layout from 'manage-tritag/components/layout'
import TeamEditForm from 'manage-tritag/components/pages/teams/edit-form'
import { Box, Tab, Tabs } from '@mui/material'
import { useState } from 'react'
import TabPanel from 'manage-tritag/components/tab-panel'
import EnhancedTable from 'manage-tritag/components/table/enhanced-table'
import { Participant } from 'manage-tritag/services/api/endpoints/participants'
import { useGetTeamPlayersQuery } from 'manage-tritag/services/api/endpoints/teams'

interface HeadCell {
  disablePadding: boolean
  id: keyof Participant
  label: string
  numeric: boolean
}

const TeamPage = (props: PageProps) => {
  const { params } = props
  const [value, setValue] = useState(0)

  const { data: participantData, isLoading } = useGetTeamPlayersQuery(
    params?.id,
  )

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const headCells: HeadCell[] = [
    {
      id: 'ttr_id',
      numeric: false,
      disablePadding: false,
      label: 'ID',
    },
    {
      id: 'firstName',
      numeric: false,
      disablePadding: false,
      label: 'First Name',
    },
    {
      id: 'lastName',
      numeric: false,
      disablePadding: false,
      label: 'Last Name',
    },
  ]
  const bodyCells = ['ttr_id', 'firstName', 'lastName']

  return (
    <Layout label1="Teams" label2="Update" url="/teams">
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '1rem' }}
        >
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Team details" sx={{ fontWeight: 'bold' }} />
            <Tab label="Players" sx={{ fontWeight: 'bold' }} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <TeamEditForm {...props} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <>
            {/* <EnhancedTableFilter params={params} setParams={setParams} /> */}
            {!isLoading && participantData && (
              <EnhancedTable
                tableTitle="Teams"
                uniqueIdentifier="ttr_id"
                participant
                rows={participantData.map(
                  (participant: Participant, index) => ({
                    ...participant,
                    ttr_id: index + 1,
                  }),
                )}
                headCells={headCells}
                bodyCells={bodyCells}
              />
            )}
          </>
        </TabPanel>
      </Box>
    </Layout>
  )
}

export default withAuthenticationRequired(TeamPage)
