import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { navigate, PageProps } from 'gatsby'
import { toast } from 'react-toastify'
import { Controller, useForm } from 'react-hook-form'
import {
  useDeleteTeamMutation,
  useGetTeamQuery,
  useUpdateTeamMutation,
} from 'manage-tritag/services/api/endpoints/teams'
import { useGetAssociationsQuery } from 'manage-tritag/services/api/endpoints/associations'
import { useEffect, useState } from 'react'
import DeleteModal from 'manage-tritag/components/modal/delete_modal'
import { noLimitParams } from 'manage-tritag/utils/string'
import Spinner from 'manage-tritag/components/loading/spinner'
import { useMediaQuery } from 'react-responsive'
import {
  FormContent,
  StyledButton,
  StyledButtonDiv,
  StyledGridContainer,
  StyledTextField,
  StyledBox,
} from './styles'

const DivisionEditForm = (props: PageProps) => {
  const { params } = props
  const isMobile = useMediaQuery({ query: `(max-width: 898px)` })
  const [updateTeam, { isLoading: updateLoading }] = useUpdateTeamMutation()
  const [deleteTeam] = useDeleteTeamMutation()
  const { data: teamData, isLoading: teamLoading } = useGetTeamQuery(params?.id)
  const { data: associations, isLoading: assocLoading } =
    useGetAssociationsQuery(noLimitParams)
  const [modal, setModal] = useState(false)
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      name: '',
      association: '',
      contactName: '',
      contactEmail: '',
      contactNumber: '',
    },
  })

  useEffect(() => {
    if (teamData) {
      reset({
        name: teamData.name,
        association: teamData.association && teamData.association.toString(),
        contactName: teamData.contactName,
        contactEmail: teamData.contactEmail,
        contactNumber: teamData.contactNumber,
      })
    }
  }, [reset, teamData])

  const onSubmit = async (updateData: any) => {
    if (updateLoading) return
    await updateTeam({ ...updateData, id: params?.id }).unwrap()
    reset()
    toast.success('Team updated succesfully.')
    navigate('/teams')
  }

  const handleDelete = async () => {
    setModal(false)
    await deleteTeam(params?.id).unwrap()
    toast.success('Team deleted succesfully.')
    navigate('/teams')
  }

  if (teamLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 145px);',
        }}
      >
        <Spinner size={40} color="#008174" />
      </Box>
    )

  return (
    <StyledBox component="form" onSubmit={handleSubmit(onSubmit)}>
      <StyledGridContainer container spacing={3} justifyContent="space-between">
        <Grid item xs={12} sm={12} md={12}>
          <Typography sx={{ fontWeight: 'bold', color: '#008174' }}>
            Team details
          </Typography>

          <FormContent>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 1 }}
                  id="filled-basic"
                  label="Team Name"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />

            <FormControl variant="filled" size="small" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-filled-label">
                Association
              </InputLabel>
              <Controller
                name="association"
                control={control}
                render={({ field }) => (
                  <Select
                    inputProps={{ tabIndex: 2 }}
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    disableUnderline
                    {...field}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {!assocLoading &&
                      associations &&
                      associations.map(association => (
                        <MenuItem key={association._id} value={association._id}>
                          {association.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </FormControl>

            <Controller
              name="contactName"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 3 }}
                  id="filled-basic"
                  label="Primary contact"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />
          </FormContent>

          <FormContent>
            <Controller
              name="contactEmail"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 4 }}
                  id="filled-basic"
                  label="Primary contact email"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />

            <Controller
              name="contactNumber"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 5 }}
                  id="filled-basic"
                  label="Primary contact number"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />

            {!isMobile && <Box sx={{ width: '100%' }} />}
          </FormContent>
        </Grid>
      </StyledGridContainer>
      <StyledButtonDiv>
        <div>
          <StyledButton
            variant="contained"
            onClick={() => {
              setModal(true)
            }}
            style={{
              backgroundColor: 'red',
              color: '#fff',
            }}
          >
            DELETE
          </StyledButton>
        </div>

        <div>
          <StyledButton
            variant="outlined"
            onClick={() => {
              navigate('/teams')
            }}
            sx={{
              border: '2px solid #008174',
              color: '#000',
              marginRight: '1.5rem',
            }}
          >
            CANCEL
          </StyledButton>
          <StyledButton
            variant="contained"
            color="primary"
            type="submit"
            sx={{ width: '83px' }}
          >
            {updateLoading ? <Spinner size={22} /> : 'SAVE'}
          </StyledButton>
        </div>
      </StyledButtonDiv>
      {modal && (
        <DeleteModal
          setModal={setModal}
          label="team"
          handleDelete={handleDelete}
        />
      )}
    </StyledBox>
  )
}

export default DivisionEditForm
